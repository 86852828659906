define("discourse/plugins/discourse-rss-polling/discourse/controllers/admin-plugins-rss-polling", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "@ember-decorators/object", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings"], function (_exports, _controller, _object, _computed, _service, _utils, _object2, _ajaxError, _decorators, _discourseI18n, _rssPollingFeedSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsRssPollingController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "feedSettings", [(0, _computed.alias)("model")]))();
    #feedSettings = (() => (dt7948.i(this, "feedSettings"), void 0))();
    saving = false;
    valid = false;
    disabled = true;
    unsavable(valid, saving) {
      return !valid || saving;
    }

    // TODO: extract feed setting into its own component && more validation
    static #_3 = (() => dt7948.n(this.prototype, "unsavable", [(0, _decorators.default)("valid", "saving")]))();
    validate() {
      let overallValidity = true;
      this.get("feedSettings").forEach(feedSetting => {
        const localValidity = !(0, _utils.isBlank)(feedSetting.feed_url) && !(0, _utils.isBlank)(feedSetting.author_username);
        (0, _object.set)(feedSetting, "valid", localValidity);
        overallValidity = overallValidity && localValidity;
      });
      this.set("valid", overallValidity);
    }
    static #_4 = (() => dt7948.n(this.prototype, "validate", [(0, _object2.observes)("feedSettings.@each.{feed_url,author_username}")]))();
    create() {
      let newSetting = {
        feed_url: null,
        author_username: null,
        discourse_category_id: null,
        discourse_tags: null,
        feed_category_filter: null,
        disabled: false,
        editing: true
      };
      this.get("feedSettings").addObject(newSetting);
    }
    static #_5 = (() => dt7948.n(this.prototype, "create", [_object.action]))();
    destroyFeedSetting(setting) {
      this.dialog.deleteConfirm({
        message: (0, _discourseI18n.i18n)("admin.rss_polling.destroy_feed.confirm"),
        didConfirm: async () => {
          try {
            await _rssPollingFeedSettings.default.deleteFeed(setting);
            this.get("feedSettings").removeObject(setting);
          } catch (error) {
            (0, _ajaxError.popupAjaxError)(error);
          } finally {
            this.set("saving", false);
          }
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "destroyFeedSetting", [_object.action]))();
    editFeedSetting(setting) {
      (0, _object.set)(setting, "disabled", false);
      (0, _object.set)(setting, "editing", true);
    }
    static #_7 = (() => dt7948.n(this.prototype, "editFeedSetting", [_object.action]))();
    cancelEdit(setting) {
      if (!setting.id) {
        this.get("feedSettings").removeObject(setting);
      }
      (0, _object.set)(setting, "disabled", true);
      (0, _object.set)(setting, "editing", false);
    }
    static #_8 = (() => dt7948.n(this.prototype, "cancelEdit", [_object.action]))();
    async updateFeedSetting(setting) {
      this.set("saving", true);
      try {
        await _rssPollingFeedSettings.default.updateFeed(setting);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.set("saving", false);
        (0, _object.set)(setting, "disabled", true);
        (0, _object.set)(setting, "editing", false);
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "updateFeedSetting", [_object.action]))();
    updateAuthorUsername(setting, selected) {
      (0, _object.set)(setting, "author_username", selected.firstObject);
    }
    static #_10 = (() => dt7948.n(this.prototype, "updateAuthorUsername", [_object.action]))();
  }
  _exports.default = AdminPluginsRssPollingController;
});